import React from 'react'

export const NoPage = () => {

  document.title = 'Eme / No page';

  return (
    <div>NoPage</div>
  )
}

export default NoPage;
